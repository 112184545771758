export const portfolioItems = [
  {
    id: 22,
    title: "Drafft",
    description:
      "Drafft is a collaborative design and development tool for easy and organised game development.",
    category: "App Development",
    skills: ["React", "Mobx", "Electron", "Pouchdb", "Couchdb", "CI/CD"],
    img: "img/drafft.jpg",
    url: "https://baj.itch.io/drafft",
    icon: "img/drafft_icon.png",
  },
  {
    id: 21,
    title: "Airwindows Cheatsheet",
    description: "Cheatsheet for airwindows plugins.",
    category: "Web Development",
    skills: ["Svelte", "Bulma", "Travis"],
    img: "img/airwindows-cheatsheet.png",
    url: "https://github.com/ajboni/airwindows-cheatsheet",
    icon: "img/icons8-web-design-96.png",
  },
  {
    id: 20,
    title: "Ulauncher pipewire runtime settings",
    description: "Ulauncher extension to change PipeWire runtime settings.",
    category: "App Development",
    skills: ["Python"],
    img: "img/ulauncher-pw.png",
    url: "https://github.com/ajboni/ulauncher-pipewire-runtime-settings",
    icon: "img/icons8-github-100.png",
  },
  {
    id: 19,
    title: "Calvo",
    description: "A jalv based lv2 plugin rack for your terminal. ",
    category: "App Development",
    skills: ["Node.js", "jack", "lv2", "Linux"],
    img: "img/calvo.png",
    url: "https://github.com/ajboni/calvo",
    icon: "img/icons8-github-100.png",
  },
  {
    id: 18,
    title: "Arduino Chess Clock",
    description:
      "archckl - A simple but functional chess clock made with arduino nano and a few components.",
    category: "App Development",
    skills: ["C++", "Arduino", "Electronics"],
    url: "https://github.com/ajboni/archclk",
    icon: "img/icons8-chess-clock-96.png",
    img: "img/archclck.jpg",
  },

  {
    id: 17,
    title: "Stuck in a drum loop",
    description:
      "This game is an experiment done in 72hs for for Ludum Dare 47 game jam. Theme: Stuck in a loop. It's an excuse to learn about Tone.js",
    category: "Game Development",
    skills: ["Tone.js", "Svelte", "HTML", "CSS"],
    img: "img/siadl.png",
    url: "https://github.com/ajboni/ld47-stuck-in-a-drum-loop",
    icon: "img/icons8-control-96.png",
  },
  {
    id: 16,
    title: "Docurry",
    description: "A Spicy, Zero-Config Documentation Site Generator ",
    category: "Web Development",
    skills: ["Node.js", "HTML", "CSS"],
    img: "img/docurry.jpg",
    url: "https://github.com/ajboni/docurry",
    icon: "img/icons8-github-100.png",
  },

  {
    id: 15,
    title: "Ateitis Corp",
    description:
      "Headless CMS and Ecommerce for Ateitis Corp made with React, Gatsby and Wordpress.",
    category: "Web Development",
    skills: ["React", "GraphQL", "Wordpress", "WooCommerce"],
    img: "img/ateitis.png",
    url: "https://ateitiscorp.com",
    icon: "img/icons8-web-design-96.png",
  },
  //   {
  //     id: 13,
  //     title: "Jardeco",
  //     description: "Landing page and e-commerce site for local garden shop.",
  //     category: "Web Development",
  //     skills: ["Wordpress", "WooCommerce", "Elementor"],
  //     img: "img/jardeco.png",
  //     url: "https://jardeco.com.ar",
  //     icon: "img/icons8-web-design-96.png",
  //   },
  {
    id: 12,
    title: "Pomodolfo",
    description: "Extremely simple multiplatform pomodoro timer for desktop.",
    category: "App Development",
    skills: ["Svelte", "Electron", "Bulma", "Butler"],
    img: "img/pomodolfo.png",
    url: "https://baj.itch.io/pomodolfo",
    icon: "img/pomodolfo-icon.png",
  },
  {
    id: 11,
    title: "Svelte Static Blog Generator",
    description:
      "Static blog generator powered by svelte, sapper and tailwindcss .",
    category: "App Development",
    skills: ["Svelte", "Sapper", "TailwindCSS", "Travis"],
    img: "img/blog.png",
    url: "https://github.com/ajboni/svelte-sapper-static-blog",
    icon: "img/icons8-pencil-64.png",
  },
  {
    id: 10,
    title: "TXTdit",
    description:
      "TXTDIT is an open-source featurless themeable text editor. Main features: No text formatting, Multiple Themes, Import/export. Autosave. No more features",
    category: "App Development",
    skills: ["Svelte", "CSS", "Travis", "Github Pages", "CI/CD"],
    img: "img/txtdit.png",
    url: "https://github.com/ajboni/txtdit",
    icon: "img/icons8-pencil-64.png",
  },
  {
    id: 1,
    title: "Scram (WIP)",
    description:
      "Slot Car Race Manager, server, and racer database for DIY slot car racer track controlled by Arduino. A rather complex project combining several technologies that I was interested in learning.",
    category: "App Development",
    skills: [
      "React",
      "Mobx",
      "GraphQL",
      "Hasura",
      "Postgres",
      "CI/CD",
      "Python",
      "Arduino",
      "SocketIO",
      "Flask",
    ],
    img: "img/scram.png",
    url: "https://github.com/ajboni/slot-car-race-manager",
    icon: "img/icons8-car-64.png",
  },
  {
    id: 3,
    title: "How To Cope With Boredom and Loneliness",
    description:
      "A free Steam game I've made with @poffle as Point Bleep Studios",
    category: "Game Development",
    skills: ["C#", "Unity", "Soundtrack", "Steamworks"],
    img: "img/htc.png",
    url: "https://store.steampowered.com/app/797390/How_To_Cope_With_Boredom_and_Loneliness/",
    icon: "img/icons8-steam-96.png",
  },
  {
    id: 4,
    title: "Personal Portfolio Website",
    description:
      "Landing page for my portfolio (this site). A great excuse to learn about Svelte.",
    category: "Web Development",
    skills: ["Svelte", "Milligram", "CI/CD"],
    img: "img/portfolio.png",
    url: "https://github.com/ajboni/webpage",
    icon: "img/icons8-web-design-96.png",
  },
  {
    id: 6,
    title: "The Mind Of Marlo",
    description: "A Steam game I've made with @poffle as Point Bleep Studios",
    category: "Game Development",
    skills: ["C#", "Unity", "Soundtrack", "Steamworks"],
    img: "img/mom.png",
    url: "https://store.steampowered.com/app/722870/The_Mind_of_Marlo/",
    icon: "img/icons8-steam-96.png",
  },
  {
    id: 7,
    title: "Anotador de Truco Simple ",
    description:
      "Really simple, free, no-ads and open source anottation tool for the popular argentinean game 'Truco'",
    category: "Game Development",
    skills: ["Godot", "GDScript", "Google Play"],
    img: "img/anotador_truco.png",
    url: "https://play.google.com/store/apps/details?id=anotador.de.truco.simple&hl=es_419",
    icon: "img/google_play.png",
  },
  {
    id: 8,
    title: "Arduino Midi Foot Controller",
    description: "A simple midi (foot) controller for Arduino Uno  ",
    category: "App Development",
    skills: ["C++", "Arduino", "Electronics"],
    img: "img/controller.jpg",
    url: "https://github.com/ajboni/ArduinoMidiFootController",
    icon: "img/icons8-github-100.png",
  },

  {
    id: 9,
    title: "Point Bleep Studios Games",
    description:
      "Under the name of Point Bleep Studios, @poffle and I developed several games that can be played for free at itch.io ",
    category: "Game Development",
    skills: ["C#", "Unity", "Soundtrack"],
    img: "img/pbs.png",
    url: "https://pointbleepstudios.itch.io/",
    icon: "img/icons8-control-96.png",
  },
];
